@use "mixins" as *
.container
  @include flex
  height: var(--sportsNav_height)
  padding: 0 4px
  background: var(--sportsNav_bg)
  overflow-x: auto
  overflow-y: hidden

.container::-webkit-scrollbar
  display: none

.noGame
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 90%
  margin: 0

.favorites
  composes: sportItem from "components/pages/sport/base/sport/sport.module.sass"

.active
  composes: active from "components/pages/sport/base/sport/sport.module.sass"

.gamesCount
  composes: gamesCount from "components/pages/sport/base/sport/sport.module.sass"
